import './App.css';

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";     
import "primeflex/primeflex.css"

import { Menubar } from 'primereact/menubar';

import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import { Route, Routes, useNavigate } from 'react-router-dom';

function App() {

    const navigate = useNavigate();

    const items = [
        {
            label: 'Home', 
            icon: 'pi pi-fw pi-home', 
            command: () => { navigate('/')}
        },
        {
            label: 'About', 
            icon: 'pi pi-fw pi-calendar', 
            command: () => { navigate('/about')}
        },
        {   
            label: 'Products', 
            icon: 'pi pi-fw pi-list', 
            command: () => { navigate('/products')}
        }
    ];

  return (<>
            <div className="container">
                <div className="content">
                    <Menubar model={ items }/>

                    <Routes>
                        <Route path="/" element= {<Home/>}/>
                        <Route path="/about" element= {<About/>}/>
                        <Route path="/products" element= {<Products/>}/>

                    </Routes>
                </div>
            </div>
            <footer className="footer">
                (c)Crystal Healing Jewellery
            </footer>
        </>

  );
}

export default App;
