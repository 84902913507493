import { useEffect, useState } from "react";
import commerce from "../lib/commerce";
import { Card } from 'primereact/card';
import parse from 'html-react-parser';

const Products = () => {

    const [products, setProducts] = useState({});

    const fetchProducts = () => {

        commerce.products.list().then(products => {
            setProducts(products.data);
        }).catch(error => {
            console.log("error", error);
        })
    }

    useEffect(() => {
        fetchProducts();
    });

    const listTemplate = (product, index) => { 

        console.log("Rendering");
        console.log(product);

        return (
            <div className="col-12" key={ product.id }>
                { product.name }
            </div>
        )
    }

    return (
        <div>
            <h1>Product List</h1>
            <div className="product-list">
                { products && products.map && products.map(product=>{
                    return (
                        <div key = {product.id} className="p-col-12 p-md-3 p-lg-4">
                        <Card title={product.name} className="md:w-25rem">
                            <div className="card-container">
                                <img src={product.image.url} width="200"/>
                            </div>
                            { parse(product.description) }
                            <div><strong>Price:</strong>{ product.price.formatted_with_symbol }</div>
                        </Card>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default Products;