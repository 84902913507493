import { useEffect, useState } from "react";
import commerce from "../lib/commerce";
import parse from 'html-react-parser';

const About = () => {

    const [address, setAddress] = useState({});

    useEffect(() => {

        commerce.merchants.about()
            .then(about => {
                console.log(about);
                let merchantInfo = about.data[0];
                console.log(merchantInfo);

                setAddress(merchantInfo.address);
                //setImage(merchantInfo.images.cover.url)
            })
            .catch(err => {
                console.log("error", err);
            });
    }, []);

    return (
        <div>
            <h1>About</h1>

            <div>
            <table>
                <tbody>
                    {Object.entries(address).map(([fieldName, fieldValue]) => (
                    <tr key={fieldName}>
                        <td>{fieldName.replace(/_/g, ' ').toUpperCase()}</td>
                        <td>{fieldValue}</td>
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>

        </div>
    );
}


export default About;