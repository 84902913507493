import { useEffect, useState } from "react";
import commerce from "../lib/commerce";
import parse from 'html-react-parser';

const Home = () => {

    const [storeDescription, setStoreDescription] = useState('');
    const [image, setImage] = useState('');

    useEffect(() => {

        commerce.merchants.about()
            .then(about => {
                console.log(about);
                let merchantInfo = about.data[0];
                console.log(merchantInfo);

                setStoreDescription(merchantInfo.description);
                setImage(merchantInfo.images.cover.url)
            })
            .catch(err => {
                console.log("error", err);
            });
    }, []);
    return (
        <div className="container">
            
            <div className="content">
                <div className="row">
                    <div>
                        <img src={ image } width="250"/>
                    </div>
                    <div>
                        { parse(storeDescription) }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;