import Commerce from '@chec/commerce.js';

//const checAPIKey = process.env.REACT_APP_CHEC_PUBLIC_KEY;
const checAPIKey = "pk_test_553203f316843933451cf4ce71554e8184a454635d36d";
const devEnvironment = process.env.NODE_ENV === 'development';

const commerceConfig = {
    axiosConfig: {
        headers: {
            'x-Chec-Agent': 'commerce.js/v2', 
            'Check-Version': '2021-09-29'
        }
    }
}

//console.log(process.env.NODE_ENV);
//console.log(checAPIKey);

if (devEnvironment && !checAPIKey) {
    throw Error('Your public API key must be provided as an environment variable named NEXT_PUBLIC_CHEC_PUBLIC_KEY. Obtain your Chec public key by logging into your Chec account and navigate to Setup > Developer, or can be obtained with the Chec CLI via with the command chec whoami');
  }

export default new Commerce(
    checAPIKey, 
    devEnvironment, 
    commerceConfig
)